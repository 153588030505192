html {
  height: 100%;
  height: 100%;
  margin: 0 auto;
}

body {
  width:100%;
  font-family: "微軟正黑體", "メイリオ", "ＭＳ Ｐゴシック", Osaka, "ヒラギノ角ゴ Pro W3", 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #404040;
  position: relative;
  display: block;
  height: 100%;
  background:#666666;
  overflow-x: hidden; 
}

.app-wrapper {
  position: relative;
  z-index: 1;
  padding: 0 0 15px 0;
  background:#ffffff;
}

.app-wrapper .app-contents {
  height:100vh;
  min-height: 100vh;
}

/* desktop */
@media screen and (min-width:768px) {
  .app-wrapper {
    background-color: #ffffff;
    display: table;
    width: 768px;
    margin: 0 auto;
    padding:0 0 15px 0;
  }
}


#progbackground{
  background-image: url('/images/prog.png');
  width:100%;
  height:300px;

}
.Jp50{
  margin-top: 20px;
}
.Jp50 table{
  width:100%;
  max-width:100%;
}
.Jp50 table td{

  background-color: #ffffff;
  border:1px solid #dddddd;
}
.Jp50 .btnJp{
  margin: 2px 2px 0 2px;
  font-size: 24px;
  background-color: #ffffff;
  color:#000000;
  border: 0 ;
}

#navbar, #navbar div{
  background: #02aa02;
}
#navbar a, #navbar button{
  color:#eeeeee!important;
  font-weight: 500!important;
}

